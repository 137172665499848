export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  REBECCA_PURPLE: '#663399',
  BLUE: '#1a58cc',
};

export const NAV_ITEMS = [
  { to: '/', label: 'Home' },
  { to: '/privacy', label: 'Privacy' },
  { to: '/terms', label: 'Terms' },
  // { href: '/docs/', label: 'Storybook' },
];
